export function CloseMd() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-close-md">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m18 18l-6-6m0 0L6 6m6 6l6-6m-6 6l-6 6"
			/>
		</symbol>
	);
}
