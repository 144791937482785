export function Building01Custom() {
	return (
		<symbol viewBox="0 0 80 80" id="icon-building-01-custom">
			<path
				d="M50 47.9v18.8H13.3V47.9c0-1.8 0-2.6.2-3.5.2-.7.5-1.4 1-2 .4-.7 1-1.3 2.4-2.4l7.6-7c2.6-2.2 3.8-3.3 5.2-3.8 1.3-.3 2.6-.3 3.9 0 1.4.5 2.7 1.6 5.2 3.9l7.7 6.9c1.3 1.1 2 1.7 2.4 2.4.4.6.7 1.3.9 2 .2.9.2 1.7.2 3.5Z"
				fill="#E6F2F3"
			/>
			<path
				d="M6.7 66.7h6.6m0 0H50m-36.7 0V47.9c0-1.8 0-2.6.2-3.5.2-.7.5-1.4 1-2 .4-.7 1-1.3 2.4-2.4l7.6-7c2.6-2.2 3.8-3.3 5.2-3.8 1.3-.3 2.6-.3 3.9 0 1.4.5 2.7 1.6 5.2 3.9l7.7 6.9c1.3 1.1 2 1.7 2.4 2.4.4.6.7 1.3.9 2 .2.9.2 1.7.2 3.5v18.8m0 0h16.7m0 0h6.6m-6.6 0V24c0-3.7 0-5.6-.8-7a6.7 6.7 0 0 0-2.9-3c-1.4-.7-3.3-.7-7-.7H34c-3.7 0-5.6 0-7 .8a6.7 6.7 0 0 0-3 2.9c-.7 1.4-.7 3.3-.7 7v9.3"
				stroke="#027F88"
				stroke-width="2.7"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</symbol>
	);
}
