export function Map() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-map">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M15 6v15m0-15l6-3v15l-6 3m0-15L9 3m6 18l-6-3m0 0l-6 3V6l6-3m0 15V3"
			/>
		</symbol>
	);
}
