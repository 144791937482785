export function ArrowCircleRight() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-arrow-circle-right">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m13 15l3-3m0 0l-3-3m3 3H8m13 0a9 9 0 1 0-18 0a9 9 0 0 0 18 0"
			/>
		</symbol>
	);
}
