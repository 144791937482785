export function UsersCustom() {
	return (
		<symbol viewBox="0 0 61 60" id="icon-users-custom">
			<path d="M13.17 22.5a10 10 0 1 0 20 0 10 10 0 0 0-20 0Z" fill="#E6F2F3" />
			<path
				d="M53.17 50c0-4.35-4.18-8.06-10-9.43m-5 9.43c0-5.52-6.72-10-15-10-8.29 0-15 4.48-15 10m30-17.5a10 10 0 0 0 0-20m-15 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20Z"
				stroke="#027F88"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</symbol>
	);
}
