export function ArrowUpRightMd() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-arrow-up-right-md">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M7 17L17 7m0 0H9m8 0v8"
			/>
		</symbol>
	);
}
