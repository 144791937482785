export function User02() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-user-02">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M20 21c0-2.761-3.582-5-8-5s-8 2.239-8 5m8-8a5 5 0 1 1 0-10a5 5 0 0 1 0 10"
			/>
		</symbol>
	);
}
