export function MapCustom() {
	return (
		<symbol viewBox="0 0 61 60" id="icon-map-custom">
			<path d="m52.83 45-15 7.5-15-7.5-15 7.5V15l15-7.5 15 7.5 15-7.5V45Z" fill="#E6F2F3" />
			<path
				d="M37.83 15v37.5m0-37.5 15-7.5V45l-15 7.5m0-37.5-15-7.5m15 45-15-7.5m0 0-15 7.5V15l15-7.5m0 37.5V7.5"
				stroke="#027F88"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</symbol>
	);
}
