import { createContext, For, onMount, splitProps, useContext } from 'solid-js';
import { createStore, produce } from 'solid-js/store';
import { twMerge } from '@troon/tailwind-preset/merge';
import { isServer } from 'solid-js/web';
import { icons } from './icons';
import type { ParentProps } from 'solid-js';
import type { SetStoreFunction } from 'solid-js/store';
import type { IconProps } from './props';

export type IconName = keyof typeof icons;

const [store, setStore] = createStore<Array<IconName>>([]);

const Context = createContext<[Array<IconName>, SetStoreFunction<Array<IconName>>]>([store, setStore]);

export function IconProvider(props: ParentProps) {
	const arr: Array<IconName> = [];
	const store = isServer ? [arr, () => {}] : createStore<Array<IconName>>([]);

	return (
		<Context.Provider
			// @ts-expect-error we're cheating here for SSR support
			value={store}
		>
			{props.children}
		</Context.Provider>
	);
}

export function Icon(props: IconProps & { name: keyof typeof icons }) {
	const [, svgProps] = splitProps(props, ['title', 'name']);
	const [store, setStore] = useContext(Context);

	onMount(() => {
		if (!isServer) {
			setStore(
				produce((s) => {
					if (!s.includes(props.name)) {
						s.push(props.name);
					}
				}),
			);
		}
	});
	if (isServer && !store.includes(props.name)) {
		store.push(props.name);
	}

	return (
		<svg
			viewBox="0 0 24 24"
			height="1.2em"
			width="1.2em"
			fill="none"
			stroke="none"
			{...svgProps}
			class={twMerge('inline-flex', svgProps.class)}
			aria-hidden={props.title ? 'false' : 'true'}
			xmlns="http://www.w3.org/2000/svg"
		>
			<use href={`#icon-${props.name}`} />
		</svg>
	);
}

export function IconMap() {
	const [store] = useContext(Context);

	return (
		<svg width="0" height="0" class="hidden" id="theiconmap">
			<For each={store}>
				{(icon) => {
					// eslint-disable-next-line import/namespace
					const IconComponent = icons[icon];
					return <IconComponent />;
				}}
			</For>
		</svg>
	);
}

export { icons };
