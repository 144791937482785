export function ChevronRight() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-chevron-right">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m9 5l7 7l-7 7"
			/>
		</symbol>
	);
}
