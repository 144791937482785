export function GolfCartCustom() {
	return (
		<symbol viewBox="0 0 80 80" id="icon-golf-cart-custom">
			<mask id="a" fill="#fff">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M13 13.5c-.6.1-1.2.7-1.4 1.2-.3 1.2.6 2.5 1.8 2.5h7.3v21.4H18.4v-1.3l-.1-8.3c0-3.8 0-7-.2-7a167.3 167.3 0 0 0-11.4 4.8l2.2.5 7.9 1.7v9.6h-1.5c-1.9.1-2.5.4-3.4 1.4-1 1.1-1 1.5-1 6.7v4.5h-.7c-1.3.2-1.6 1-1.5 4.2.2 2.3.2 2.3 2.1 2.4 1.7 0 1.5 0 1.7-1a9.2 9.2 0 0 1 8.8-7.3c3 0 5 .7 7 2.6 1 1 1.3 1.4 1.8 2.4.4.7.7 1.7.9 2.2l.2 1h19.5l.1-.4a10 10 0 0 1 4.8-6.6 9.4 9.4 0 0 1 13.7 5.7l.4 1.2h3.1l.3-.5c.2-.4.2-.9.2-2.6 0-2.9 0-3.1-1.6-3.1h-.8l-.2-1c-.1-1-.4-1.4-2-2.9-1.7-1.6-5-4-6.5-4.5-.6-.2-.6-.3-2.1-3.8a833.7 833.7 0 0 1-9.2-21.7c0-.1.9-.2 3.6-.2 3.6 0 3.7 0 4.1-.4a2 2 0 0 0 .8-2.1 2 2 0 0 0-.4-.8c-.7-.7 0-.7-23.5-.7-12.7 0-22.3.1-22.5.2ZM30 17.3h-5.8V28l.1 10.7h3.9c3.6.1 3.8.2 4.4.5.6.3 1.4 1.1 1.7 1.7l.1 5.3V51h2.2c1.3 0 2.1 0 2.1-.2l1-5.8c.5-1.7 1-2.2 3.1-3.5l1.9-1.2c.2-.2 0-.3-.9-1.2L40.7 36 38.5 34l-.7.8c-.8 1-1.4 1.3-2.4 1.3-1.4 0-2.6-1.2-2.5-2.6.1-.9.4-1.2 3-3.7 2.1-2.1 2.5-2.3 3.6-2 .8.3 1.4.8 1.7 1.6.5 1 .3 1.7-.6 2.7l-.6.6 3.3 3.3 3.4 3.3 2-1c3.5-2 4.2-2.3 5.2-2.4l.9-.1-.8-2a678.8 678.8 0 0 1-7-16.4c0-.2-.3-.2-16.9-.1ZM19.6 50.9c-1.4.4-2.3.9-3.4 1.8a7.5 7.5 0 0 0-2.5 5.5c-.1 1.7 0 2.6.8 4 1 2.2 3.2 3.9 5.5 4.3 1.3.3 3.5.1 4.7-.3 3.2-1.2 5.2-4 5.2-7.4 0-1.5-.2-2.4-.9-3.7a8.1 8.1 0 0 0-9.4-4.2ZM58 51c-2.7.7-5 3-5.7 5.6a11 11 0 0 0 0 4.4c.3 1.2 1 2.5 2 3.4a7.8 7.8 0 0 0 6.2 2.3c1 0 1.8-.1 2.4-.3 2.3-.9 4.4-3 5-5.4.3-1 .3-3.6 0-4.5-.8-2.7-3.3-5-6-5.6-1-.2-3-.2-3.9 0Zm-37.3 4.5c-1 .3-1.6.9-2 1.8-1.4 2.7 1.1 5.6 4.1 4.7 1.8-.5 2.8-2.7 2-4.5-.4-1-1.4-1.8-2.3-2-.9-.2-1-.2-1.8 0Zm38 .2A3.3 3.3 0 0 0 57 60c.6 1.4 1.5 2 3.1 2 1.1 0 1.9-.3 2.6-1 1.4-1.4 1-4-.7-5.1a4 4 0 0 0-3.3-.3Z"
				/>
			</mask>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M13 13.5c-.6.1-1.2.7-1.4 1.2-.3 1.2.6 2.5 1.8 2.5h7.3v21.4H18.4v-1.3l-.1-8.3c0-3.8 0-7-.2-7a167.3 167.3 0 0 0-11.4 4.8l2.2.5 7.9 1.7v9.6h-1.5c-1.9.1-2.5.4-3.4 1.4-1 1.1-1 1.5-1 6.7v4.5h-.7c-1.3.2-1.6 1-1.5 4.2.2 2.3.2 2.3 2.1 2.4 1.7 0 1.5 0 1.7-1a9.2 9.2 0 0 1 8.8-7.3c3 0 5 .7 7 2.6 1 1 1.3 1.4 1.8 2.4.4.7.7 1.7.9 2.2l.2 1h19.5l.1-.4a10 10 0 0 1 4.8-6.6 9.4 9.4 0 0 1 13.7 5.7l.4 1.2h3.1l.3-.5c.2-.4.2-.9.2-2.6 0-2.9 0-3.1-1.6-3.1h-.8l-.2-1c-.1-1-.4-1.4-2-2.9-1.7-1.6-5-4-6.5-4.5-.6-.2-.6-.3-2.1-3.8a833.7 833.7 0 0 1-9.2-21.7c0-.1.9-.2 3.6-.2 3.6 0 3.7 0 4.1-.4a2 2 0 0 0 .8-2.1 2 2 0 0 0-.4-.8c-.7-.7 0-.7-23.5-.7-12.7 0-22.3.1-22.5.2ZM30 17.3h-5.8V28l.1 10.7h3.9c3.6.1 3.8.2 4.4.5.6.3 1.4 1.1 1.7 1.7l.1 5.3V51h2.2c1.3 0 2.1 0 2.1-.2l1-5.8c.5-1.7 1-2.2 3.1-3.5l1.9-1.2c.2-.2 0-.3-.9-1.2L40.7 36 38.5 34l-.7.8c-.8 1-1.4 1.3-2.4 1.3-1.4 0-2.6-1.2-2.5-2.6.1-.9.4-1.2 3-3.7 2.1-2.1 2.5-2.3 3.6-2 .8.3 1.4.8 1.7 1.6.5 1 .3 1.7-.6 2.7l-.6.6 3.3 3.3 3.4 3.3 2-1c3.5-2 4.2-2.3 5.2-2.4l.9-.1-.8-2a678.8 678.8 0 0 1-7-16.4c0-.2-.3-.2-16.9-.1ZM19.6 50.9c-1.4.4-2.3.9-3.4 1.8a7.5 7.5 0 0 0-2.5 5.5c-.1 1.7 0 2.6.8 4 1 2.2 3.2 3.9 5.5 4.3 1.3.3 3.5.1 4.7-.3 3.2-1.2 5.2-4 5.2-7.4 0-1.5-.2-2.4-.9-3.7a8.1 8.1 0 0 0-9.4-4.2ZM58 51c-2.7.7-5 3-5.7 5.6a11 11 0 0 0 0 4.4c.3 1.2 1 2.5 2 3.4a7.8 7.8 0 0 0 6.2 2.3c1 0 1.8-.1 2.4-.3 2.3-.9 4.4-3 5-5.4.3-1 .3-3.6 0-4.5-.8-2.7-3.3-5-6-5.6-1-.2-3-.2-3.9 0Zm-37.3 4.5c-1 .3-1.6.9-2 1.8-1.4 2.7 1.1 5.6 4.1 4.7 1.8-.5 2.8-2.7 2-4.5-.4-1-1.4-1.8-2.3-2-.9-.2-1-.2-1.8 0Zm38 .2A3.3 3.3 0 0 0 57 60c.6 1.4 1.5 2 3.1 2 1.1 0 1.9-.3 2.6-1 1.4-1.4 1-4-.7-5.1a4 4 0 0 0-3.3-.3Z"
				fill="#E6F2F3"
				stroke="#027F88"
				stroke-width="2"
				mask="url(#a)"
			/>
		</symbol>
	);
}
