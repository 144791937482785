import { Show } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Dialog as KDialog } from '@kobalte/core/dialog';
import { Icon } from '@troon/icons';
import { Button } from '../components/button';
import { useDialogContext } from '../providers/dialog';
import type { ParentProps, JSX, ComponentProps } from 'solid-js';

type DialogProps = ComponentProps<typeof KDialog> & {
	key: string;
};

export function Dialog(props: DialogProps) {
	const ctx = useDialogContext();
	return (
		<KDialog
			modal
			{...props}
			onOpenChange={(open) => {
				props.onOpenChange && props.onOpenChange(open);
				if (open) {
					ctx.onDialogOpened(props.key);
				} else if (!open) {
					ctx.onDialogClosed(props.key);
				}
			}}
		/>
	);
}

Dialog.Trigger = KDialog.Trigger;

export function DialogTrigger(props: ComponentProps<(typeof KDialog)['Trigger']> & ComponentProps<typeof Button>) {
	return <KDialog.Trigger {...props} as={Button} />;
}

type NoHeaderProps = {
	header?: never | undefined;
	headerLevel?: never | undefined;
};
type HeaderProps = {
	header: JSX.Element;
	headerLevel: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

type Props = {
	/**
	 * A unique identifier for the onDialogOpen and onDialogClose events
	 */
	children: JSX.Element;
	noClose?: boolean;
	floatingClose?: boolean;
	nonModal?: boolean;
	onClose?: () => void;
	autoHeight?: boolean;
	autoWidth?: boolean;
	fullWidth?: boolean;
	noPadding?: boolean;
} & (NoHeaderProps | HeaderProps);

export function DialogContent(props: Props) {
	return (
		<KDialog.Portal>
			<KDialog.Overlay class="fixed inset-0 z-40 bg-black/50 backdrop-blur-sm" />
			<div class="fixed inset-0 z-40 flex items-center justify-center">
				<KDialog.Content
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class={twJoin(
						'fixed bottom-0 w-dvw max-w-full overflow-y-auto overflow-x-hidden overscroll-contain rounded-t bg-white shadow-xl outline-none animate-out fade-out slide-out-to-bottom anim-duration-200 ui-expanded:animate-in ui-expanded:fade-in ui-expanded:slide-in-from-bottom ui-expanded:anim-duration-200 sm:bottom-auto sm:my-auto sm:max-h-[97dvh] sm:rounded',
						!props.autoWidth ? 'sm:h-fit sm:w-full sm:max-w-xl' : 'sm:size-fit',
						props.autoWidth && !props.fullWidth ? 'sm:max-w-4xl' : '',
						!props.autoHeight ? 'h-[97dvh]' : 'max-h-[97dvh]',
					)}
				>
					<Show when={!props.noClose || props.header}>
						<div
							class={twJoin(
								'sticky top-0 z-10 flex flex-row items-center justify-between gap-4 bg-white',
								!!props.header && 'mb-4 border-b border-neutral-500',
								!props.noPadding ? 'py-3 pe-0 ps-6' : '',
							)}
						>
							<Show when={!!props.header}>
								<KDialog.Title as={props.headerLevel} class="grow font-semibold">
									{props.header}
								</KDialog.Title>
							</Show>
							<Show when={!props.noClose}>
								<div class="ms-auto shrink pe-2">
									<KDialog.CloseButton as={Button} appearance="transparent" tabIndex={-1}>
										<Icon name="close-md" />
										<span class="sr-only">Close</span>
									</KDialog.CloseButton>
								</div>
							</Show>
						</div>
					</Show>
					<Show when={props.floatingClose}>
						<KDialog.CloseButton
							as={Button}
							appearance="transparent-current"
							class="absolute right-4 top-4 z-10 text-brand md:text-white"
							tabIndex={-1}
						>
							<Icon name="close-md" />
							<span class="sr-only">Close</span>
						</KDialog.CloseButton>
					</Show>
					<div
						class={twJoin('self-start', !props.noPadding && 'px-4 pb-6 md:px-6')}
						classList={{ 'pt-6': props.noClose && !props.header && !props.noPadding }}
					>
						{props.children}
					</div>
				</KDialog.Content>
			</div>
		</KDialog.Portal>
	);
}

export function DialogActions(props: ParentProps) {
	return (
		<div class="flex flex-col items-stretch justify-start gap-4 sm:flex-row-reverse sm:items-center">
			{props.children}
		</div>
	);
}

export function DialogAction(props: ComponentProps<typeof Button>) {
	return <Button {...props} class="shrink grow-0" />;
}
