export function HamburgerMd() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-hamburger-md">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M5 17h14M5 12h14M5 7h14"
			/>
		</symbol>
	);
}
