export function GolfCart() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-golf-cart">
			<path
				fill="currentColor"
				fill-rule="evenodd"
				d="M2.7 3.2c-.2 0-.4.2-.4.4-.1.4.1.8.5.8h2.3V8l.1 3.5h-.7V11a80.7 80.7 0 0 1-.1-5A53.8 53.8 0 0 0 .7 7.6l.7.1 2.5.6v3.1h-.5c-.6 0-.8.1-1 .5-.4.3-.4.5-.4 2.2v1.5h-.2c-.4 0-.5.3-.5 1.3 0 .8 0 .8.7.8.5 0 .5 0 .6-.3 0-.5.4-1.2.8-1.6.6-.5 1.2-.8 2-.8 1 0 1.6.2 2.3.9l.5.7.3.8v.3H15v-.2c.2-.9.8-1.7 1.6-2.1a3 3 0 0 1 3 0c.7.4 1.2 1 1.4 1.9l.1.3h1.1v-.1l.1-.9c0-1 0-1-.5-1h-.3v-.3c0-.3-.2-.5-.7-1a9.1 9.1 0 0 0-2.1-1.4c-.2-.1-.2-.1-.7-1.3a275.7 275.7 0 0 1-3-7l1.2-.2h1.4c.2-.2.3-.5.2-.8l-.1-.2c-.3-.3 0-.3-7.6-.3H2.7Zm5.5 1.2H6.4v7.1h1.2l1.5.1.5.6v3.3h1.5l.3-2c.1-.5.3-.7 1-1l.6-.5-.3-.4-1-1-.7-.7-.3.3c-.2.3-.4.4-.7.4-.5 0-.9-.4-.8-.8 0-.3 0-.4.9-1.3.7-.7.8-.7 1.2-.6.2 0 .4.2.5.5.2.3.1.6-.2.9l-.2.2 1.1 1 1.1 1.2.7-.4c1-.6 1.3-.7 1.6-.7l.3-.1-.2-.7a227.1 227.1 0 0 1-2.3-5.3H8.2Zm-3.4 11c-.4.2-.7.3-1 .7-.6.4-.8 1-.9 1.8 0 .5 0 .8.3 1.3.3.7 1 1.2 1.8 1.4l1.5-.1c1-.4 1.7-1.4 1.7-2.5l-.3-1.2a2.6 2.6 0 0 0-3-1.3Zm12.5 0c-.9.3-1.6 1-1.9 1.9v1.4l.7 1.2a2.5 2.5 0 0 0 2 .7l.8-.1c.7-.3 1.4-1 1.6-1.8a4 4 0 0 0 0-1.5c-.3-.8-1-1.6-2-1.8h-1.2ZM5.3 17a1 1 0 0 0-.7.6c-.5 1 .3 1.9 1.3 1.6.6-.2 1-1 .7-1.5-.2-.3-.5-.6-.8-.7h-.6Zm12.2.1a1 1 0 0 0-.5 1.5c.2.4.5.6 1 .6.3 0 .6-.1.8-.3.5-.5.3-1.4-.2-1.7-.3-.2-.8-.2-1 0Z"
				clip-rule="evenodd"
			/>
		</symbol>
	);
}
