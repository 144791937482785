export function CircleWarning() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-circle-warning">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M12 8.45v4M12 21a9 9 0 1 1 0-18a9 9 0 0 1 0 18m.05-5.55v.1h-.1v-.1z"
			/>
		</symbol>
	);
}
