export function ArrowLeftMd() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-arrow-left-md">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M19 12H5m0 0l6 6m-6-6l6-6"
			/>
		</symbol>
	);
}
