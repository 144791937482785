export function CaretUpMd() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-caret-up-md">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m8 14l4-4l4 4"
			/>
		</symbol>
	);
}
