export function Grid() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-grid">
			<path
				fill="currentColor"
				d="M20 20h-4v-4h4v4Zm-6 0h-4v-4h4v4Zm-6 0H4v-4h4v4Zm12-6h-4v-4h4v4Zm-6 0h-4v-4h4v4Zm-6 0H4v-4h4v4Zm12-6h-4V4h4v4Zm-6 0h-4V4h4v4ZM8 8H4V4h4v4Z"
			/>
		</symbol>
	);
}
