export function SearchMagnifyingGlass() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-search-magnifying-glass">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m15 15l6 6m-11-4a7 7 0 1 1 0-14a7 7 0 0 1 0 14"
			/>
		</symbol>
	);
}
