export function ArrowsReload01Custom() {
	return (
		<symbol viewBox="0 0 61 60" id="icon-arrows-reload-01-custom">
			<path
				d="M25.67 40h-12.5v12.5M35.67 20h12.5V7.5m-36.05 15a20 20 0 0 1 35.83-2.56m1.26 17.55a20 20 0 0 1-35.83 2.57"
				stroke="#027F88"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</symbol>
	);
}
