export function Flag() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-flag">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M4 21v-5.313m0 0c5.818-4.55 10.182 4.55 16 0V4.313c-5.818 4.55-10.182-4.55-16 0z"
			/>
		</symbol>
	);
}
