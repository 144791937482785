export function CircleCheck() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-circle-check">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m15 10l-4 4l-2-2m3 9a9 9 0 1 1 0-18a9 9 0 0 1 0 18"
			/>
		</symbol>
	);
}
