export function ChevronLeft() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-chevron-left">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m15 19l-7-7l7-7"
			/>
		</symbol>
	);
}
