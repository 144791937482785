export function Info() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-info">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M12 11v5m0 5a9 9 0 1 1 0-18a9 9 0 0 1 0 18m.05-13v.1h-.1V8z"
			/>
		</symbol>
	);
}
