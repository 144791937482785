export function CaretDownMd() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-caret-down-md">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m16 10l-4 4l-4-4"
			/>
		</symbol>
	);
}
