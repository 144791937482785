export function ArrowsReload01() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-arrows-reload-01">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M10 16H5v5m9-13h5V3M4.583 9.003a8 8 0 0 1 14.331-1.027m.504 7.021a8 8 0 0 1-14.332 1.027"
			/>
		</symbol>
	);
}
