export function ArrowRightMd() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-arrow-right-md">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M5 12h14m0 0l-6-6m6 6l-6 6"
			/>
		</symbol>
	);
}
