export function Check() {
	return (
		<symbol viewBox="0 0 24 24" id="icon-check">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="m6 12l4.243 4.243l8.484-8.486"
			/>
		</symbol>
	);
}
